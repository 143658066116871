@import "config";

* {
padding: 0;
margin: 0;
list-style-type: none;
border: none;
box-sizing: border-box;
}

@include font-face('NotoRegular', '../fonts/NotoRegular');
@include font-face('NotoMedium', '../fonts/NotoMedium');
@include font-face('GeneiKoburiMincho', '../fonts/GeneiKoburiMincho');

::selection {
background-color: #4b4b4b;
color: #fff;
}

body {
@include font-size(14);
@include text-color();

@include breakpoint(mobile) {
@include font-size(13,24);
}


a:link {
@include text-color();
text-decoration: none;
}

a:hover {
@include text-color();
text-decoration: none;
}

a:action {
@include text-color();
text-decoration: none;
}

a:visited {
@include text-color();
text-decoration: none;
}

img {
display: block;
}

h1,h2,h3,h4,h5,h6 {
@include font-size(16);
font-weight: normal;
@include text-color();
    
@include breakpoint(mobile) {
@include font-size(16);
}
}

#credit {
font-family: 'Lato', sans-serif;
@include font-size(12,12);
text-align: center;
letter-spacing: 0.01em;
padding: 0 0 10px 0;
}
}